<template>
  <div class="datenschutzerklärung">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h4 mb-1">Datenschutzerklärung</h1>
          <v-divider class="my-3"></v-divider>

          <h2 class="mb-2">
            Informationspflicht laut
            <a
              href="https://www.gesetze-im-internet.de/tmg/__5.html"
              target="_blank"
              >§ 5 TMG</a
            >
          </h2>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>

          <h2 class="mb-2">Datenschutz "Need to Know"</h2>
          <h4 class="mb-1">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </h4>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Die Kontaktdaten können Sie dem
            <router-link to="/Impressum">Impressum</router-link> dieser Website
            entnehmen.
          </p>
          <h4 class="mb-1">Wie werden Ihre Daten erfasst?</h4>
          <p>
            Ihre Daten können zum einen dadurch erhoben werden, dass Sie und
            diese mitteilen. Dies geschieht dann, wenn es sich z.B. um Daten
            handelt, die Sie in ein Kontaktformular eingeben.<br />
            Andere Daten werden automatisch beim Aufruf/Besuch dieser Website
            über unsere IT-Systeme erfasst. Hierbei handelt es sich vor allem um
            technische Daten wie z.B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs. Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie unsere Website betreten.
          </p>
          <h4 class="mb-1">Wozu werden Ihre Daten verwendet?</h4>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten, andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>

          <h2 class="mb-2">Allgemeine Hinweise und Pflichtinformationen</h2>
          <h4 class="mb-1">Datenschutz</h4>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <h4 class="mb-1">Verantwortlicher</h4>
          <p>
            Marvin Rust<br />
            Fuhlsbüttler Straße 302<br />
            22307 Hamburg,<br />
            Deutschland
          </p>
          <h4 class="mb-1">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </h4>
          <p>
            Die Rechtsgrundlage besteht nach
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 a DSGVO</a
            >
            (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die
            Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten
            geben. Sie können diesen Einwilligung jederzeit widerrufen – eine
            formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im
            <router-link to="/Impressum">Impressum</router-link>.
          </p>
          <p>
            Wenn die Datenverarbeitung auf Grundlage von
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 lit. e oder f DSGVO</a
            >
            erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus
            Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
            personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für
            ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige
            Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
            dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden
            wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten,
            es sei denn, wir können zwingende schutzwürdige Gründe für die
            Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
            überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung
            oder Verteidigung von Rechtsansprüchen (Widerspruch nach
            <a href="https://dsgvo-gesetz.de/art-21-dsgvo/" target="_blank"
              >Artikel 21 Absatz 1 DSGVO</a
            >). Werden Ihre personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit
            Widerspruch gegen die Verarbeitung Sie betreffender
            personenbezogener Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden
            Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der
            Direktwerbung verwendet (Widerspruch nach
            <a href="https://dsgvo-gesetz.de/art-21-dsgvo/" target="_blank"
              >Artikel 21 Absatz 2 DSGVO</a
            >).
          </p>
          <h4 class="mb-1">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </h4>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
          <h4 class="mb-1">Recht auf Datenübertragbarkeit</h4>
          <p>
            Sie haben das Recht nach
            <a href="https://dsgvo-gesetz.de/art-20-dsgvo/" target="_blank"
              >Artikel 20 DSGVO</a
            >, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung
            eines Vertrags automatisiert verarbeiten, an sich oder an einen
            Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu
            lassen. Sofern Sie die direkte Übertragung der Daten an einen
            anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
            technisch machbar ist.
          </p>

          <h4 class="mb-1">SSL- bzw. TLS-Verschlüsselung</h4>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
            SSL-bzw. TLS-Verschlüsselung (Datenschutz durch Technikgestaltung
            <a href="https://dsgvo-gesetz.de/art-25-dsgvo/" target="_blank"
              >Artikel 25 Absatz 1 DSGVO</a
            >). Durch den Einsatz von TLS (Transport Layer Security), einem
            Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
            können wir den Schutz vertraulicher Daten sicherstellen. Eine
            verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile
            des Browsers von “http://” auf “https://” wechselt und an dem
            Schloss-Symbol in Ihrer Browserzeile.<br />
            Daten, welche mittles SSL- bzw. TLS-Verschlüsselung an uns
            übertragen werden, können nicht von Dritten mitgelesen werden.
          </p>

          <h2 class="mb-2">Datenerfassung</h2>
          <h3 class="mb-1">Cookies</h3>
          <p>
            Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
            speichern.
          </p>
          <p><strong>Was genau sind Cookies?</strong></p>
          <p>
            Immer wenn Sie durch das Internet surfen, verwenden Sie einen
            Browser. Bekannte Browser sind z.B. Chrome, Safari, Firefox,
            Internet Explorer und Microsoft Edge. Die meisten Webseiten
            speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt
            man Cookies.
          </p>
          <p><strong>Welche Arten von Cookies gibt es?</strong></p>
          <p>
            Die meisten der von uns verwendeten Cookies sind so genannte
            “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen.<br />Man kann 4 Arten von
            Cookies unterscheiden:
          </p>
          <p>
            <strong>Unbedingt notwendige Cookies</strong><br />
            Diese Cookies sind nötig, um grundlegende Funktionen der Website
            sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein
            User ein Produkt in den Warenkorb legt, dann auf anderen Seiten
            weitersurft und später erst zur Kasse geht. Durch diese Cookies wird
            der Warenkorb nicht gelöscht, selbst wenn der User sein
            Browserfenster schließt.
          </p>
          <p>
            <strong>Funktionelle Cookies</strong><br />
            Diese Cookies sammeln Infos über das Userverhalten und ob der User
            etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser
            Cookies auch die Ladezeit und das Verhalten der Website bei
            verschiedenen Browsern gemessen.
          </p>
          <p>
            <strong>Zielorientierte Cookies</strong><br />
            Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
            Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
            Formulardaten gespeichert.
          </p>
          <p>
            <strong>Werbe-Cookies</strong><br />
            Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu
            dem User individuell angepasste Werbung zu liefern. Das kann sehr
            praktisch, aber auch sehr nervig sein.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
            werden auf Grundlage von
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 f DSGVO</a
            >
            gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an
            der Speicherung von Cookies zur technisch fehlerfreien und
            optimierten Bereitstellung seiner Dienste. Soweit andere Cookies
            (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden,
            werden diese in dieser Datenschutzerklärung gesondert behandelt.
          </p>

          <h3 class="mb-1">Server-Logs</h3>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind unteranderem:
          </p>
          <ul>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage
            von
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 lit. f DSGVO</a
            >. Der Websitebetreiber hat ein berechtigtes Interesse an der
            technisch fehlerfreien Darstellung und der Optimierung seiner
            Website – hierzu müssen die Server-Log-Files erfasst werden.
          </p>

          <h3 class="mb-1">Anfrage per E-Mail</h3>
          <p>
            Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage
            inklusive aller daraus hervorgehenden personenbezogenen Daten wie
            z.B. Name, Anfrage, oder ähnliches zum Zwecke der Bearbeitung Ihres
            Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 b DSGVO</a
            >, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf Ihrer Einwilligung nach
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 a DSGVO</a
            >
            und / oder auf unseren berechtigten Interessen nach
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 f DSGVO</a
            >, da wir ein berechtigtes Interesse an der effektiven Bearbeitung
            der an uns gerichteten Anfragen haben.
          </p>

          <h3 class="mb-1">Registrierung auf dieser Website</h3>
          <p>
            Sie können sich auf unserer Website registrieren, um zusätzliche
            Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
            verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes
            oder Dienstes, für den Sie sich registriert haben. Die bei der
            Registrierung abgefragten Pflichtangaben müssen vollständig
            angegeben werden. Anderenfalls werden wir die Registrierung
            ablehnen.
          </p>
          <p>
            Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
            notwendigen Änderungen nutzen wir die bei der Registrierung
            angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
          </p>
          <p>
            Die Verarbeitung der bei der Registrierung eingegebenen Daten
            erfolgt auf Grundlage Ihrer Einwilligung nach
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 lit. a DSGVO</a
            >. Sie können eine von Ihnen erteilte Einwilligung jederzeit
            widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt
            vom Widerruf unberührt.
          </p>
          <p>
            Die bei der Registrierung erfassten Daten werden von uns
            gespeichert, solange Sie auf unserer Website registriert sind und
            werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen
            bleiben unberührt.
          </p>

          <h3 class="mb-1">Kommentarfunktion dieser Website</h3>
          <p>
            Für die Kommentarfunktion auf dieser Seite werden neben Ihrem
            Kommentar auch Angaben zum Zeitpunkt der Erstellung des Kommentars,
            Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen
            gewählte Nutzername gespeichert.
          </p>

          <h4 class="mb-1">Speicherung Ihrer IP-Adresse</h4>
          <p>
            Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die
            Kommentare verfassen. Da wir Kommentare auf unserer Seite nicht vor
            der Freischaltung prüfen, benötigen wir diese Daten, um im Falle von
            Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den
            Verfasser vorgehen zu können.
          </p>

          <h4 class="mb-1">Speicherdauer der Kommentare</h4>
          <p>
            Die Kommentare und die damit verbundenen Daten (z.B. IP-Adresse)
            werden gespeichert und verbleiben auf unserer Website, bis der
            kommentierte Inhalt vollständig gelöscht wurde oder die Kommentare
            aus rechtlichen Gründen gelöscht werden müssen (z.B. beleidigende
            Kommentare).
          </p>

          <h3 class="mb-1">Verarbeiten von Daten</h3>
          <p>
            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
            Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
            Aufbewahrungsfristen bleiben unberührt.
          </p>

          <h3 class="mb-1">Datenübermittlung bei Vertragsschluss</h3>
          <p>
            Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies
            im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der
            Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
            Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
            Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer
            Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der
            Werbung, erfolgt nicht.
          </p>

          <h2 class="mb-2">Analyse-Tools und Werbung</h2>
          <p></p>
          <p></p>

          <h2 class="mb-2">Plugins und Tools</h2>
          <h3 class="mb-1">YouTube Datenschutzerklärung</h3>
          <p>
            Wir haben auf unserer Website YouTube-Videos eingebaut. So können
            wir Ihnen interessante Videos direkt auf unserer Seite präsentieren.
            YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von
            Google ist. Betrieben wird das Videoportal durch YouTube, LLC, 901
            Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Website
            eine Seite aufrufen, die ein YouTube-Video eingebettet hat,
            verbindet sich Ihr Browser automatisch mit den Servern von YouTube
            bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten
            übertragen. Für die gesamte Datenverarbeitung im europäischen Raum
            ist Google Ireland Limited (Gordon House, Barrow Street Dublin 4,
            Irland) verantwortlich.
          </p>
          <p>
            Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video
            eingebaut hat, setzt YouTube zumindest ein Cookie, das Ihre
            IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto
            eingeloggt sind, kann YouTube Ihre Interaktionen auf unserer
            Webseite meist mithilfe von Cookies Ihrem Profil zuordnen.
          </p>
          <p>
            Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
            Interesse im Sinne von
            <a href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank"
              >Artikel 6 Absatz 1 f DSGVO</a
            >
            dar.
          </p>
        </v-col>
        <v-col cols="12" class="mx-auto text-center">
          <v-btn @click="top">Zum Anfang der Seite</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    top() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
</style>